@import '../../styles/mixins';

$largest-size: 1440px;
$large-size: 1280px;
$medium-size: 1024px;
$small-size: 768px;
$small-mid-size: 667px;
$smallest-size: 480px;
$ultra-smallest-size: 375px;

$transition-slow: 450ms ease;
$transition-base: 300ms ease;

$font-size-base: 16;
$font-family-base: 'Open Sans', sans-serif;
$font-family-solid: 'Montserrat', sans-serif;
$font-family-italic: 'Libre Baskerville', sans-serif;

$color-blue: #006CA9;
$color-orange: $color-blue;
$color-orange-dark: $color-blue;
$color-orange-1: #FF6B58;
//$color-orange-dark: #c94a24;

$color-laguna: #00A3AF;
$color-laguna-light: #F2FAFB;
$color-laguna-dark: #007178;
//$color-laguna-dark: #00ADB5;

$color-grey-light: #adadad;
$color-grey: #676767;

$primary-1: #ae0032;
$primary-2: #455162;
$primary-3: #1f2f46;

$neutral-1: #0d1720;
$neutral-2: #636363;
$neutral-3: #dadada;
$neutral-4: #f2f2f2;
$neutral-5: #fcfbfa;

.s-exp-advisors__container {
    padding-bottom: 60px;

    position: relative;
    overflow: hidden;

    @media (min-width: $medium-size) {
        padding-top: 200px;
        padding-bottom: 200px;

        overflow: visible;
    }

    &_min-padding {
        @media (min-width: $large-size) {
            margin-top: -50px;
            padding-bottom: 75px;
        }
        @media (min-width: $largest-size) {
            margin-top: -100px;
        }
    }
}
.s-exp-advisors__wr-bg {
    width: 730px;
    height: 650px;

    @include img-wrap;

    position: absolute;
    top: 0;
    z-index: -1;
    transform: translateX(-35%);

    @media (min-width: $medium-size) {
        width: 1100px;
        height: 1000px;
    }
    @media (min-width: $large-size) {
        transform: translateX(-15%);
    }

    img,
    svg {
        object-fit: contain;
    }
}

.s-exp-advisors__head {
    display: flex;
    flex-direction: column;
    align-items: start;

    margin-bottom: 30px;

    @media (min-width: $medium-size) {
        display: grid;
        grid-template-columns: auto auto;
        flex-direction: initial;
        align-items: initial;
        grid-row-gap: 20px;

        margin-bottom: 40px;
    }

}
.s-exp-advisors__heading {
    margin-bottom: 20px;

    @media (min-width: $medium-size) {
        grid-column: 1 / 2 span;

        margin-bottom: 0;
    }
}
.s-exp-advisors__heading-italic {
    display: block;

    @media (min-width: $small-size) {
        display: inline-block;

        margin-left: 16px;
    }
}
.s-exp-advisors__head-text {
    max-width: 670px;
}
.s-exp-advisors__head-link {
    margin-top: 30px;

    @media (min-width: $small-size) {
        margin-left: auto;
    }
    @media (min-width: $medium-size) {
        justify-self: end;

        margin-left: 0;
        margin-top: 6px;
    }
}

.bl-advisors-list {
    display: grid;
    grid-row-gap: 30px;

    @media (min-width: $ultra-smallest-size) {
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 15px;
    }
    @media (min-width: $small-mid-size) {
        grid-column-gap: 30px;
    }
    @media (min-width: $small-size) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-column-gap: 15px;
    }
    @media (min-width: $large-size) {
        grid-column-gap: 30px;
    }
}
.bl-advisors-list__item {
    display: flex;
    flex-direction: column;
    align-items: start;

    &_cta {
        align-items: center;
        justify-content: center;

        padding-top: 60px;
        padding-bottom: 60px;

        color: #ffffff;
        text-align: center;

        background-color: $color-orange-dark;

        @media (min-width: $ultra-smallest-size) {
            padding-top: 40px;
            padding-bottom: 40px;
        }
        @media (min-width: $smallest-size) {
            padding-right: 20px;
            padding-left: 20px;
        }
    }
}
.bl-advisors-list__cta-text {
    margin-bottom: 20px;

    line-height: 160%;

    @media (min-width: $ultra-smallest-size) {
        max-width: 85px;
    }
    @media (min-width: $small-mid-size) {
        max-width: none;
    }
}
.bl-advisors-list__cta-btn {
    color: $color-orange-dark;
}
